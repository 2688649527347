import React from "react";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";


// reactstrap components
import {
  Alert,
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Label,
  Modal,
  Form
} from "reactstrap";

// core components
import SolidNavbar from "components/Navbars/SolidNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function LandingPage() {
  const recaptcha = React.useRef();

  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);

  const [modalLok, setModalLok] = React.useState(false);
  const [modalKor, setModalKor] = React.useState(false);
  const [modalSC, setModalSC] = React.useState(false);
  const [modalSwaerter, setModalSwaerter] = React.useState(false);
  const [modalWartung, setModalWartung] = React.useState(false);
  const [modalGleisb, setModalGleisb] = React.useState(false);

  const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
  const [dangerAlertOpen, setDangerAlertOpen] = React.useState(false);

  const toggleSuccessAlert = () => {
    setSuccessAlertOpen(!successAlertOpen);
  };

  const toggleDangerAlert = () => {
    setDangerAlertOpen(!dangerAlertOpen);
  };

  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    message: '',
    checkbox_ds: ''
  });

  const [errors, setErrors] = React.useState({
    name: '',
    email: '',
    message: '',
    checkbox_ds: ''
  });

  const validateForm = () => {
    let isValid = true;
    const updatedErrors = {
      name: '',
      email: '',
      message: '',
      checkbox_ds: ''
    };

    // Name validation
    if (formData.name.trim() === '') {
      updatedErrors.name = 'Bitte geben Sie Ihren Namen ein';
      isValid = false;
    }
    // Email validation
    if (!formData.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
      updatedErrors.email = 'Bitte fügen Sie eine gültige Email hinzu';
      isValid = false;
    }

    // Message validation
    if (formData.message.trim() === '') {
      updatedErrors.message = 'Bitte fügen Sie eine Nachricht hinzu';
      isValid = false;
    }

    if (formData.checkbox_ds === "false") {
      updatedErrors.message = 'Bitte akzeptieren Sie die Datenschutzerklärung';
      isValid = false;
    }


    setErrors(updatedErrors);

    //recaptcha validation
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      const lookie = process.env.REACT_APP_EMAIL;

      var formParams = {
        user_name: formData.name,
        user_email: formData.email,
        message: formData.message
      }

      emailjs.send('service_cqfd7c8', 'template_yg6fou6', formParams, lookie)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });

      setFormData({ name: '', email: '', message: '', checkbox_ds: '' });
      recaptcha.current.reset();

      toggleSuccessAlert();

    } else {

      toggleDangerAlert();
    }
  };

  const handleChange = (e) => {
    const { name, value, checked} = e.target;

    if (name === "checkbox_ds"){
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };


  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  return (
    <>
      <SolidNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-story-overview">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 id="company" className="title" style={{ scrollMarginTop: "8rem" }}>Unternehmen</h2>
                <h5 className="text-muted">
                  Unser Unternehmen ist Ihr verlässlicher Partner für die Vermittlung und den Verleih von qualifiziertem Bahnpersonal in der Schweiz. Wir bringen Fachkräfte und Unternehmen zusammen, um den reibungslosen Betrieb des Bahnverkehrs in der Schweiz sicherzustellen. Vertrauen Sie auf unsere langjährige Expertise und unser engagiertes Team, um Ihre Personalanforderungen effizient zu erfüllen.
                  <br></br><br></br>Willkommen bei Swiss Rail Services GmbH – Ihrem Partner für bahnspezifisches Personalmanagement.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
          </Container>
        </div>

        <Container style={{ backgroundColor: "#f5f5f5" }}>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 id="services" className="title" style={{ scrollMarginTop: "8rem" }}>Unsere Dienstleistungen</h2>
              <h5 className="text-muted">
                Das Wesentliche im Bereich qualifizierten Bahnpersonals ist eine solide Ausbildung, Freundlichkeit und die Freude an der Arbeit. Selbstverständlich ist es Ihr Ziel, sicherzustellen, dass sowohl Ihre Fahrgäste als auch wertvolle Ausrüstung mit höchster Sorgfalt und Sicherheit behandelt werden. Für sämtliche relevanten Einsatzbereiche stellen wir Ihnen geeignete Fachkräfte zur Verfügung.
              </h5>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>

          <Row xs="1" md="3" lg="3" className="ml-auto mr-auto">

            <Col md="4" className="d-flex">
              <Card className="flex-fill">
                <CardImg src={require("assets/img/lokf.JPG")} srcSet={require("assets/img/lokf.webp")} type="image/webp" position="top" alt="Lokfuehrer"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Lokführer:in / Rangierleiter:in</CardTitle>
                    <CardText>
                      Sind für das Rangieren von Zügen auf Bahnhöfen oder gesperrten Gleisen verantwortlich.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalLok(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="d-flex">
              <Card className="flex-fill">
                <CardImg src={require("assets/img/Koordinator.jpeg")} srcSet={require("assets/img/Koordinator.webp")} type="image/webp" position="top" alt="Koordinator"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Arbeitsstellenkoordinator:in</CardTitle>
                    <CardText>
                      Verantwortlich für die Koordination von Renovierungsarbeiten rund um die Baustelle.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalKor(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="d-flex">
              <Card className="flex-fill">
                <CardImg src={require("assets/img/sc.jpg")} srcSet={require("assets/img/sc.webp")} type="image/webp" position="top" alt="Sicherheitschef"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Sicherheitschef:in</CardTitle>
                    <CardText>
                      Der Sicherheitschef übernimmt die Verantwortung für die Sicherheit bei Arbeiten im Gleisbereich.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalSC(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="d-flex">

              <Card className="flex-fill">
                <CardImg src={require("assets/img/sw.JPG")} srcSet={require("assets/img/sw.webp")} type="image/webp" position="top" alt="Sicherheitswaerter"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Sicherheitswärter:in</CardTitle>
                    <CardText>
                      Ist für den Schutz des Personals vor den Gefahren des Bahnbetriebs verantwortlich.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalSwaerter(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="d-flex">
              <Card className="flex-fill">
                <CardImg src={require("assets/img/wartung.jpg")} srcSet={require("assets/img/wartung.webp")} type="image/webp" position="top" width="100%" alt="Wartung"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Wartung</CardTitle>
                    <CardText>
                      Wir bieten Personal für die Wartung von Bahnanlagen und deren Geräten an.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalWartung(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="4" className="d-flex">

              <Card className="flex-fill">
                <CardImg src={require("assets/img/Gleisbau.jpg")} srcSet={require("assets/img/Gleisbau.webp")} type="image/webp" position="top" width="100%" alt="Gleisbau"></CardImg>
                <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div>
                    <CardTitle tag="h4">Gleisbauer:in</CardTitle>
                    <CardText>
                      Gleisbauer:in bauen und unterhalten die Fahrbahnbereiche und sorgen für Sicherheit.
                    </CardText>
                  </div>
                  <div style={{ padding: '2rem' }}></div>
                  <div style={{ bottom: '3%', position: 'absolute' }}>
                    <Button
                      color="danger"
                      onClick={() => setModalGleisb(true)}
                    >
                      Infos
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          <div className="section separator separator-primary"></div>
          <h2 id="team" className="title" style={{ scrollMarginTop: "8rem" }}>Unser Team</h2>
          <div className="team">
            <Row>
              <Col md="4">
                <div className="team-player">
                  <picture>
                    <source srcSet={require("assets/img/sj.webp")} type="image/webp"></source>
                    <img
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/sj.JPG")}
                      style={{ height: 180, width: 180 }}
                      alt="Samantha Jashari"
                    ></img>
                  </picture>
                  <h4 className="title">Samantha Jashari</h4>
                  <p className="category text-danger">Chief Operating Officer (COO)</p>
                  <p className="description text-muted">
                    Meine Aufgaben umfassen die operative Leitung und strategische Planung des Unternehmens sowie die Verantwortung für das Personalmanagement und den Personalaustausch in Zusammenarbeit mit unseren Partnern.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <picture>
                    <source srcSet={require("assets/img/fj.webp")} type="image/webp"></source>
                    <img
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/fj.JPG")}
                      style={{ height: 180, width: 180 }}
                      alt="Fitim Jashari"
                    ></img>
                  </picture>
                  <h4 className="title">Fitim Jashari</h4>
                  <p className="category text-danger">Chief Executive Officer (CEO)</p>
                  <p className="description text-muted">
                    Als Geschäftsführer obliegt mir die Verantwortung für die strategische Ausrichtung, das Wachstum und die langfristige Erfolgsperspektive des Unternehmens.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="team-player">
                  <picture>
                    <source srcSet={require("assets/img/pd.webp")} type="image/webp"></source>
                    <img
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/pd.JPG")}
                      style={{ height: 180, width: 180 }}
                      alt="Patrick Dorna"
                    ></img>
                  </picture>
                  <h4 className="title">Patrick Dorna</h4>
                  <p className="category text-danger">Chief Information Officer (CIO)</p>
                  <p className="description text-muted">
                    Meine Verantwortung umfasst die Ausgestaltung der IT-Strategie und die Verwaltung der Technologieinfrastruktur des Unternehmens, um die Erreichung der Geschäftsziele zu fördern und die Effizienz zu steigern.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="section">
          <Container style={{ backgroundColor: "#f5f5f5" }}>
            <h2 id="contact" className="title text-center">Kontakt</h2>
            <p className="text-muted text-center">Ihr Projekt ist für uns bedeutend.</p>

            <Row>
              <Col className="ml-auto mr-auto" md="5">

                <Form id="contact-form" method="post" role="form" style={{ marginTop: "3rem" }} onSubmit={handleSubmit}>
                  <Label for="name">Ihr Name</Label>


                  <InputGroup
                    className={
                      "input-lg" + (firstFocus ? " input-group-focus" : "")
                    }
                  >

                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ backgroundColor: "#f5f5f5" }} >
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Name..."
                      type="text"
                      name="name"
                      id="name"
                      onFocus={() => setFirstFocus(true)}
                      onBlur={() => setFirstFocus(false)}
                      value={formData.name}
                      onChange={handleChange}
                      minLength="3"
                      required
                    ></Input>
                  </InputGroup>


                  <Label for="email">Ihre Email</Label>
                  <InputGroup
                    className={
                      "input-lg" + (lastFocus ? " input-group-focus" : "")
                    }
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{ backgroundColor: "#f5f5f5" }}>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email..."
                      type="email"
                      name="email"
                      id="email"
                      onFocus={() => setLastFocus(true)}
                      onBlur={() => setLastFocus(false)}
                      value={formData.email}
                      onChange={handleChange}
                      required
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    ></Input>
                  </InputGroup>
                  <div className="textarea-container">
                    <Input
                      cols="80"
                      name="message"
                      placeholder="Ihre Nachricht..."
                      rows="4"
                      type="textarea"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      minLength="10"
                    ></Input>
                  </div>

                  <div className="send-button submit text-center">
                    <br></br><br></br>
                    <InputGroup>
                      <InputGroupText style={{ backgroundColor: "#f5f5f5", border: "0" }}>
                        <Input
                          addon
                          aria-label="Checkbox for following text input"
                          type="checkbox"
                          name="checkbox_ds"
                          id="checkbox_ds"
                          value={formData.check}
                          onChange={handleChange}
                          required
                        />
                      </InputGroupText>
                      <Input 
                        readOnly 
                        type="textarea" 
                        cols="80" 
                        rows="5"
                        style={{ backgroundColor: "#f5f5f5", border: "0", maxHeight: "100px"}} 
                        placeholder="Durch Klick auf den nachfolgenden Button erklären Sie sich damit einverstanden, dass Ihre Angaben gemäss Datenschutzerklärung weiterverarbeitet werden."
                        ></Input>
                    </InputGroup>

                    <br></br><br></br>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE} ref={recaptcha} />
                    <Button
                      className="btn-round btn"
                      color="danger"
                      size="lg"
                      style={{ margin: "60px 1px" }}
                      type="submit"
                    >
                      Absenden
                    </Button>
                  </div>
                </Form>
              </Col>

              <Col className="ml-auto mr-auto" md="5">
                <div style={{ paddingBottom: "3rem", paddingTop: "auto", textAlign: "left !important", marginTop: "3rem", marginLeft: "auto", padding: "auto" }}>

                  <div className="icon icon-info" style={{ float: "left", marginTop: "0.1rem", marginRight: "2rem" }}>
                    <i className="now-ui-icons location_pin" style={{ fontSize: "2rem" }}></i>
                  </div>

                  <div className="description" style={{ marginLeft: "4rem" }}>
                    <h4>Unser Standort</h4>
                    <p >Sihlbruggstrasse 105 <br></br>
                      6340 Baar
                      <br></br>
                      Schweiz</p>
                  </div>


                  <div className="icon icon-info" style={{ float: "left", marginTop: "1rem", marginRight: "2rem" }}>
                    <i className="now-ui-icons tech_mobile" style={{ fontSize: "2rem" }}></i>
                  </div>

                  <div className="description" style={{ marginLeft: "4rem" }}>
                    <h4>Telefon</h4>
                    <p >Empfang <br></br>
                      +41 78 248 00 69
                      <br></br>
                      Mo - Fr, 09:00-17:00</p>
                  </div>

                  <div className="icon icon-info" style={{ float: "left", marginTop: "1rem", marginRight: "2rem" }}>
                    <i className="now-ui-icons ui-1_email-85" style={{ fontSize: "2rem" }}></i>
                  </div>

                  <div className="description" style={{ marginLeft: "4rem" }}>
                    <h4>Mail</h4>
                    <p >info@swissrailservices.ch </p>
                  </div>
                </div>


              </Col>
            </Row>
          </Container>
        </div>


        <Alert color="danger" isOpen={dangerAlertOpen} toggle={toggleDangerAlert}>
          <div className="container">
            <div className="alert-icon">
              <i className="now-ui-icons ui-1_bell-53"></i>
            </div>
            <strong>Die Kontaktanfrage ist fehlgeschlagen.</strong> Bitte probieren Sie es erneut oder kontaktieren Sie uns direkt über die angegebenen Kontaktdaten.
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => toggleDangerAlert()}
            >
            </button>
          </div>
        </Alert>

        <Alert isOpen={successAlertOpen} color="success" toggle={toggleSuccessAlert}>
          <div className="container">
            <div className="alert-icon">
              <i className="now-ui-icons ui-2_like"></i>
            </div>
            <strong>Die Kontaktanfrage wurde erfolgreich abgesendet!</strong> Sie erhalten so bald wie möglich eine Rückmeldung über die angegebene Email.
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={() => toggleSuccessAlert()}
            >
            </button>
          </div>
        </Alert>

        <DefaultFooter />

        <Modal toggle={() => setModalLok(false)} isOpen={modalLok} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalLok">
              Lokführer:in / Begleiter:in
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalLok(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Der Lokführer für Baustellen ist ein hochspezialisierter Beruf im Bahnverkehr, der eine wichtige Rolle bei Bau- und Instandhaltungsarbeiten an den Gleisen spielt. Diese Fachleute sind dafür verantwortlich, speziell ausgestattete Lokomotiven oder Arbeitszüge zu steuern, um schwere Maschinen und Baumaterialien sicher und effizient zu Baustellen entlang des Schienennetzes zu transportieren.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalLok(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>

        <Modal toggle={() => setModalKor(false)} isOpen={modalKor} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Arbeitstellenkoordinator:in
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalKor(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Der Arbeitsstellenkoordinator im Bahnverkehr spielt eine wichtige Rolle bei der Planung und Überwachung von Arbeiten an den Bahnumbauten. Seine Hauptaufgabe besteht darin, die Kommunikation zwischen verschiedenen Parteien, darunter Fahrdienstleiter, Lokführer, Sicherheitschef und andere relevante Interessengruppen, sicherzustellen. Eine klare Kommunikation ist entscheidend, um sicherzustellen, dass alle Beteiligten über den Fortschritt der Arbeiten informiert sind.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalKor(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>

        <Modal toggle={() => setModalSC(false)} isOpen={modalSC} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Sicherheitschef:in
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalSC(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Ein Sicherheitschef im Bahnverkehr spielt eine entscheidende Rolle bei der Gewährleistung der Sicherheit im gesamten Bahnnetz. Die Hauptaufgabe dieses verantwortungsvollen Berufs besteht darin, die im Sicherheitsdispositiv (Prosec) festgelegten Sicherheitsmassnahmen strikt einzuhalten und umzusetzen.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalSC(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>

        <Modal toggle={() => setModalSwaerter(false)} isOpen={modalSwaerter} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Sicherheitswärter:in
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalSwaerter(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Die Tätigkeit eines Sicherheitswärters im Bahnverkehr ist von grosser Bedeutung für die Sicherheit und den reibungslosen Ablauf des Zugverkehrs. Sicherheitswärter sind verantwortlich für die Überwachung und Sicherstellung der Sicherheit an Bahnbaustellen im Schienennetz.
              Die Hauptaufgabe eines Sicherheitswärters besteht darin, sicherzustellen, dass sich keine Personen, Fahrzeuge oder Hindernisse auf den Gleisen befinden, wenn ein Zug naht.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalSwaerter(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>

        <Modal toggle={() => setModalWartung(false)} isOpen={modalWartung} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Wartung
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalWartung(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Die regelmässige Wartung von Bahnanlagen ist von entscheidender Bedeutung, um einen sicheren und effizienten Bahnverkehr zu gewährleisten. Wartungsarbeiten umfassen die Inspektion, Instandhaltung und Reparatur von Serviceanlagen.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalWartung(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>

        <Modal toggle={() => setModalGleisb(false)} isOpen={modalGleisb} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Gleisbauer:in
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalGleisb(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Gleise müssen regelmässig auf Risse, Verschleiss und Beschädigungen überprüft werden, um Unfälle zu verhindern. Die Arbeit erfordert spezialisierte Kenntnisse, Fähigkeiten und die Einhaltung strenger Sicherheitsvorschriften, da Gleisarbeiten in der Nähe von fahrenden Zügen stattfinden können. Gleisbauer spielen eine entscheidende Rolle für die Zuverlässigkeit und Sicherheit des Bahnverkehrs.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalGleisb(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>


      </div>
    </>
  );
}

export default LandingPage;
