import React from "react";

// reactstrap components
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    Button
} from "reactstrap";

function SolidNavbar() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    var elms = document.querySelectorAll("#navbutton");
    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 399 ||
                document.body.scrollTop > 399
            ) {
                setNavbarColor("navbar-solid");
                for(let i = 0; i < elms.length; i++){
                    elms[i].classList.add('btn-neutral');
                    elms[i].classList.remove('btn-outline-white'); 
                }
                    
            } else if (
                document.documentElement.scrollTop < 400 ||
                document.body.scrollTop < 400
            ) {
                setNavbarColor("navbar-transparent");
                for(let i = 0; i < elms.length; i++){
                    elms[i].classList.add('btn-outline-white');
                    elms[i].classList.remove('btn-neutral'); 
                }
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar className={"fixed-top " + navbarColor} color="white" expand="lg">


                <Container>

                    <div className="container-fluid" style={{ display: "flex", alignItems: "center" }}>

                        <NavbarBrand href="#">
                            <div className="navbar-logo"
                                style={{
                                    backgroundImage: "url(" + require("assets/img/Swiss_Rail_Services.png") + ")",
                                    width: 200,
                                    height: 100,
                                    backgroundSize: "contain",
                                    backgroundColor: "transparent",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                }}
                            />
                        </NavbarBrand>
                        <div className="navbar-translate">
                        </div>
                        <button
                            className="navbar-toggler"
                            onClick={() => {
                                // for(let i = 0; i < elms.length; i++){
                                //     elms[i].classList.remove('btn-outline-white'); 
                                //     elms[i].classList.add('btn-neutral');
                                // }
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span className="navbar-toggler-bar bar1"></span>
                            <span className="navbar-toggler-bar bar2"></span>
                            <span className="navbar-toggler-bar bar3"></span>
                        </button>
                    </div>

                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav navbar>
                            <NavItem>
                                <Button
                                    className="nav-link btn-outline-white"
                                    color="default"
                                    href="#company"
                                    id="navbutton"
                                    size="sm"
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <p>Unternehmen</p>
                                </Button>

                            </NavItem>

                            <NavItem>
                                <Button
                                    className="nav-link btn-outline-white"
                                    color="default"
                                    href="#services"
                                    id="navbutton"
                                    size="sm"
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <p>Dienstleistungen</p>
                                </Button>

                            </NavItem>

                            <NavItem>
                                <Button
                                    className="nav-link btn-outline-white"
                                    color="default"
                                    href="#team"
                                    id="navbutton"
                                    size="sm"
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <p>Team</p>
                                </Button>

                            </NavItem>


                            <NavItem>
                                <Button
                                    className="nav-link btn-danger"
                                    color="danger"
                                    href="#contact"
                                    id="contactbutton"
                                    size="sm"
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <p className="danger">Kontakt</p>
                                </Button>

                            </NavItem>

                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default SolidNavbar;
