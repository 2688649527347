/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Modal, Button } from "reactstrap";

// core components

function DefaultFooter() {
  const [modalDS, setModalDS] = React.useState(false);
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                <a>
                  Swiss Rail Services GmbH
                </a>
              </li>
              <li>
                <a onClick={() => setModalDS(true)}>
                  Datenschutzerklärung
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed and Coded by{" "}
            <a>
              dornasec&#169;
            </a>

          </div>
        </Container>
      </footer>

      <Modal toggle={() => setModalDS(false)} isOpen={modalDS} className="modal-lg">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalKor">
              Datenschutzerklärung
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalDS(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Diese Datenschutzerklärung klärt die Nutzer unserer Webseite über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch Swiss Rail Services GmbH auf.</p>
            <h4>1. Erhebung und Verarbeitung von Daten</h4>
            <p>Wenn Sie unser Kontaktformular nutzen, werden die von Ihnen eingegebenen Daten (z. B. Name, E-Mail-Adresse, Nachricht) zur Bearbeitung Ihrer Anfrage gespeichert. Diese Daten werden nur für die Abwicklung Ihrer Anfrage und für den Fall von Anschlussfragen gespeichert.</p>
            <h4>2. Weitergabe von Daten</h4>
            <p>Wir geben Ihre Daten nicht an Dritte weiter, es sei denn, Sie haben ausdrücklich eingewilligt oder wir sind gesetzlich dazu verpflichtet.</p>
            <h4>3. Dauer der Speicherung</h4>
            <p>Ihre Daten werden nur solange gespeichert, wie es für den Zweck, für den sie erhoben wurden, erforderlich ist.</p>
            <h4>4. Ihre Rechte</h4>
            <p>Sie haben das Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten.</p>
            <h4>5. Kontakt</h4>
            <p>Für Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten und für Auskünfte, Berichtigung, Sperrung oder Löschung von Daten wenden Sie sich bitte an:
            <br></br>
            <br></br>Swiss Rail Services GmbH
            <br></br>Sihlbruggstrasse 105
            <br></br>6340 Baar
            <br></br>info@swissrailservices.ch
            </p>
            <h4>6. Änderungen dieser Datenschutzerklärung</h4>
            <p>Diese Datenschutzerklärung kann sich gelegentlich ändern, um sie aktuellen rechtlichen Anforderungen anzupassen. Die jeweils aktuelle Datenschutzerklärung finden Sie hier auf unserer Webseite.</p>
          </div>
          <div className="modal-footer">
            <Button
              color="danger"
              type="button"
              onClick={() => setModalDS(false)}
            >
              Schliessen
            </Button>
          </div>
        </Modal>
    </>
  );
}

export default DefaultFooter;
